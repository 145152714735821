body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}





@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}





@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

x html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Segoe UI', sans-serif, Arial;
}

/* .content {
      padding: 20px;
      min-height: 90.6%;
      margin: 0 auto -50px;
    } */

.navbar-default .navbar-brand {
  float: none;
  color: #fff;
  font-size: 28px;
  line-height: 42px;
  padding-bottom: 0;
  padding-top: 0;


}

.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-nav>li>a:hover {
  color: #fff;
}

.navbar-nav {
  margin: 0;
}

/* .footer,
  .push {
    height: 50px;
  } */

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #2BDA8E;
  color: white !important;
  text-align: center;
  align-items: center;
  display: inline-grid;
}

.form-group {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.navbar-default {
  background-color: #2BDA8E;
  border: 0;
  border-radius: 0;
  /*text-align: center;*/
}

.btn {
  background-color: #2BDA8E !important;
  color: white !important;

}

.backgrounddiv {
  background: url(/static/media/Background.9d497ca3.png);
  width: auto; 
  height: auto;
  background-repeat: no-repeat;
}

.container-login {
  padding-top: 100px;
  padding-bottom: 300px;
}
.container-login_2 {
  padding-top: 15px;
}

.error {
  color: #a94442;
  border-color: #a94442;
  text-decoration: none;
}


.hr-main {
  min-height: calc(100% - 120px);
}

.hr-main h2 {
  margin-bottom: 20px;
}

label{
  font-weight: bold;
}

label.error {
  font-size: 12px;
  color: #a94442;
  font-weight: normal;
  margin: 3px 0 0 2px;
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;

}

.login-content {
  max-width: 400px;
  margin: 40px auto;
}

.common-inner-content {
  margin: 30px auto;
}

.doc-search-content-inner {
  max-width: 600px;
  text-align: center;
}

.doc-search-tbl {
  margin-top: 30px;
}

.doc-search-tbl table {
  width: 100%;
}

.doc-search-tbl .table>tbody>tr>td {
  text-decoration: none;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
}

.doc-search-tbl table td a {
  text-decoration: none;
  display: block;
  text-align: center;
}


.doc-search-tbl .doc-search-pic {
  width: 60px;
  height: 60px;
  display: inline-block;
}

.doc-search-tbl .doc-search-pic img {
  max-width: 100%;
}

.doc-search-tbl .doc-search-heading {
  margin: 10px 0 0;
}

.page-search-container {
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}

.doclist-content-inner {
  max-width: 1000px;
}

.common-inner-content .well {
  border-radius: 0;
  box-shadow: none;
  padding: 8px;
}

.common-inner-content .well .profile-pic {
  margin: 0 auto;
}

.common-inner-content .well h4 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 24px;
}

.common-inner-content .well p.sub {
  margin-bottom: 5px;
  margin-bottom: 5px;
  line-height: 15px;
}

.common-inner-content .well .social-sec span.like-pic {
  width: 25px;
  height: 25px;
  overflow: hidden;
  float: left;
  margin-right: 10px;
}

.common-inner-content .well .social-sec span.like-text {
  width: auto;
  height: 25px;
  float: left;
  font-size: 20px;
  line-height: 30px;
}

.doc-profile-content-inner {
  max-width: 1000px;
}

.profile-container {
  background: #f5f5f5;
}

.profile-container header {
  border: 1px solid #e3e3e3;
  border-bottom: 0;
  padding: 8px;
}

.profile-container .well {
  background: #fff;
  border-top: 0;
}

.profile-container header .img-holder {
  float: left;
  width: 40px;
  height: 40px;
  text-align: center;
}

.profile-container header h1 {
  float: left;
  margin: 0px 0 0 20px;
  font-size: 32px;
}

.common-inner-content .profile-container .well p.sub {
  margin-bottom: 8px;
}

.common-inner-content .sub-header {
  margin: 20px 10px;
}

.profile-container .timing-table {
  margin: 0 auto;
}

/* Helper classes */
.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.font-bold {
  font-weight: bold;
}

.header{
  position:-webkit-sticky;
  position:sticky;
  top: 0 ;
}

.topmargin {
  padding-top: 3%;
}

.button {
  position: relative;
  padding: 8px 16px;
  background: #009579;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

.button:active {
  background: #007a63;
}

.button__text {
  color: #ffffff;
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  -webkit-animation: button-loading-spinner 1s ease infinite;
          animation: button-loading-spinner 1s ease infinite;
}

.dropbtn {
  background-color: #2BDA8E;
  color: white;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  text-decoration: none;
  border: 0;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

  .active .btn1:active
  {
      background-color: #666;
  }

@-webkit-keyframes button-loading-spinner {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
  }

  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes button-loading-spinner {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
  }

  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

.headerlogo{
  margin-left: 10px;
}





/* Responsive CSS */
@media screen and (max-width: 768px) {
  .navbar-collapse {
    width: 100%;
    margin: 0;
  }

  .common-inner-content .well .profile-pic {
    max-height: 200px;
    margin-bottom: 20px;
  }

  .common-inner-content .well h4 {
    text-align: center;
  }

  .common-inner-content .well p.sub,
  .common-inner-content .well p {
    text-align: center;
  }

  .common-inner-content .well .skills-sec {
    text-align: center;
  }

  .common-inner-content .well .skills-sec ul {
    display: inline-block;
  }

  .common-inner-content .well .social-sec span.like-pic,
  .common-inner-content .well .social-sec span.like-text {
    display: inline-block;
    float: none;
  }
}
